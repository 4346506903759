.homebar {
  width: 100%;            
  height: auto; /* Allow height to adjust based on content */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #404040;
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  position: sticky;
  top: 0;
  box-shadow: -3px 4px 2px -2px #27ae60;
  padding-top: 5px;
  padding-bottom: 5px;
}

.left-section {
  display: flex;
  align-items: center;
}

.logo {
  font-size: 30px;
  font-weight: bold;
  color: #27ae60;
  cursor: pointer;
  margin-right: 20px;
  line-height: 1;
  transition: 
    color 0.5s ease, 
    transform 0.2s ease-in-out, 
    box-shadow 0.3s ease-in-out;
}

.logo:hover {
  color: #ffffff;
  transform: scale(1.05);
  text-shadow: 0 0 10px rgba(39, 174, 96, 0.7);
}

.navigation ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

.navigation li {
  cursor: pointer;
  transition: 
    color 0.3s ease, 
    transform 0.2s ease-in-out, 
    box-shadow 0.3s ease-in-out;
  color: white;
}

.navigation li:hover {
  color: #27ae60;
  transform: scale(1.05);
  text-shadow: 0 0 10px rgba(39, 174, 96, 0.5);
}

.search-bar {
  flex-grow: 1;
  margin: 0 20px;
}

.search-bar input {
  width: 75%;
  padding: 10px;
  background-color: #404040;
  border: 0px solid #404040c0;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
  color: #ffffff;
}

.search-bar input:focus {
  background-color: #484848;
  border: 1px solid #27ae60;
}

.auth-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.welcome-dropdown {
  position: relative;
  margin-left: 20px;
}

.welcome-message {
  cursor: pointer;
  padding: 10px;
  border: 2px solid #27ae60;
  border-radius: 5px;
  transition: 
    background-color 0.3s ease, 
    border-color 0.3s ease, 
    color 0.5s ease, 
    transform 0.2s ease-in-out, 
    box-shadow 0.3s ease-in-out;
  display: inline-block;
  margin-right: 50px;
}

.welcome-message:hover {
  color: #ffffff;
  background-color: #27ae60;
  border-color: #2ecc71;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(39, 174, 96, 0.5);
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  max-width: 100%;
  min-width: 50px;
  background-color: #404040;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
  align-items: center;
}

.dropdown-item {
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #27ae60;
}

.welcome-dropdown:hover .dropdown {
  display: block;
}

.balance {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 2px solid #27ae60;
  border-radius: 5px;
  transition: 
    background-color 0.3s ease, 
    border-color 0.3s ease, 
    color 0.5s ease, 
    transform 0.2s ease-in-out, 
    box-shadow 0.3s ease-in-out;
  gap: 5px;
  margin-left: 20px;
}

.wallet-icon {
  color: #27ae60;
  font-size: 18px;
  display: inline-block;
  transition: color 0.3s ease;
}

.balance:hover {
  color: #ffffff;
  background-color: #27ae60;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(39, 174, 96, 0.5);
}

.balance:hover .wallet-icon {
  color: #ffffff;
}
