/* CSS styles for Banner */
.banner {
  background-color: #27ae60; /* Green background for the banner */
  color: white; /* White text for contrast */
  padding: 40px 0;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.banner h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white; /* Ensure the main title is white */
}

.banner h4 {
  font-size: 20px;
  color: #f1f1f1; /* Lighter shade of white */
  margin-top: 10px;
}

.banner h6 {
  font-size: 18px;
  color: #e1e1e1; /* Slightly lighter for subheading */
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner .icon {
  margin-left: 10px;
  font-size: 24px;
  color: #ffffff; /* Ensure the flag icon matches the text color */
}

/* Optional: Add a gradient effect to the banner */
.banner {
  background: linear-gradient(135deg, #27ae60, #1abc9c); /* Green gradient for a sleek look */
}
