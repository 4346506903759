.item-card-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
}

.cropped-image {
  width: 160px; /* Remaining 4/6 after cropping */
  height: 160px;
  overflow: hidden;
  position: relative;
}

.cropped-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
}

.item-card {
  width: 180px;
  height: 300px;
  padding: 20px;
  margin-bottom: 10px;
  background-color: #303030;
  border: 1px solid #505050;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.5s ease;
}

.item-card:hover {
  background-color: #404040; /* Darker background */
  transform: translateY(-5px); /* Slight lift on hover */
  transform: scale(1.1);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3); /* Add shadow */
  text-underline-position: below;
  text-decoration: underline;
}

.item-card:active {
  transform: translateY(0); /* Reset lift effect */
}

.item-card.highlight {
  background-color: #dddddd;
  transition: background-color 0.5s ease-in-out;
}

.item-icon {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.item-name {
  font-size: 18px;
  margin-top: 0px 0;
  max-width: 200px;
  max-height: 75px;
  color: #ffffff;
  flex: 1;
  white-space: normal;
  overflow: wrap;
  text-overflow: ellipsis;
  transition: all 0.2s ease;      /* Smooth transition for hover effects */

}
.item-name:hover {
  transform: scale(1.05);         /* Slightly larger on hover */
}

.item-card.highlight {
  background-color: #dddddd;
  transition: 0.5s ease-in-out;
}


.item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.item-price {
  font-size: 18px;
  color: #ffffff;
  /* Add emphasis with one or more of these properties: */
  font-weight: 600;                /* Make text bolder */
  letter-spacing: 0.5px;          /* Spread letters slightly */
  text-shadow: 0 0 1px #b7b7b7;   /* Subtle glow effect */
  
  /* Optional: add hover effect */
  transition: all 0.2s ease;      /* Smooth transition for hover effects */
}
.item-price:hover {
  color: #ffffff;                 /* Brighter color on hover */
  transform: scale(1.05);         /* Slightly larger on hover */
}

.item-last-traded {
  font-size: 14px;
  color: #b7b7b7;
}

.inventory-card {
  display: flex; /* Use flexbox to align elements horizontally */
  align-items: center; /* Align items vertically in the center */
  justify-content: space-between; /* Distribute space between the items */
  width: 100%; /* Ensure full width */
  min-width: 600px;
  max-width: 1600px;
  padding: 15px;
  background-color: #2f2f2f;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}

.inventory-card:hover {
  background-color: #404040;
  transform: scale(1.01);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}

.inventory-card-image {
  flex: 0 0 160px; /* Make the image section wider */
  height: 160px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 20px; /* Space between image and text */
}


.inventory-card-info {
  flex: 1; /* Allow the text information to take up remaining space */
  color: #ffffff;
  display: flex;
  flex-direction: row; /* Display info items horizontally */
  justify-content: space-between; /* Distribute items horizontally */
  align-items: center; /* Align vertically in the center */
}

.inventory-item-name {
  font-size: 18px;
  max-width: 360px; /* Limit the width of the name */
  min-width: 360px; /* Limit the width of the name */
  font-weight: bold;
  color: #ffffff;
  margin-right: 10px; /* Space between name and quantity */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.inventory-item-quantity {
  font-size: 14px;
  width: 100px;
  align-items: center;
  margin: 0 10px; /* Add some spacing between quantity and price */
  color: #bbbbbb;
}

.inventory-item-price, .inventory-item-value {
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-shadow: 0 0 1px #b7b7b7;
}

.inventory-item-value {
  margin-left: 15px; /* Add some space between price and value */
}